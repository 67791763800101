import styled from "@emotion/styled"
import { Brands, getLogo } from "./Header/Logo"
import { Paper, Typography, useTheme } from "@mui/material"

export interface ISocialStat {
  platform: Brands
  stat: string
  url: string
  verified?: boolean
  username?: string
}

export interface IStatsDisplayProps {
  stats?: ISocialStat[]
  profiles: ISocialStat[]
  scrollToPromo: (brand: Brands, url: string) => void
}

export const StatsDisplay = ({
  stats,
  profiles,
  scrollToPromo,
}: IStatsDisplayProps): JSX.Element => {
  const theme = useTheme()

  function getSortedStats(): JSX.Element[] {
    const profileCollection: ISocialStat[] = []
    stats?.forEach((stat) => {
      if (stat.stat) {
        profileCollection.push(stat)
      }
    })

    profiles.forEach((profile) => {
      if (
        stats?.filter((stat) => stat.url === profile.url && stat.stat)
          .length === 0
      ) {
        profileCollection.push(profile)
      }
    })

    profileCollection.sort((a, b) => {
      if (a.platform === "OnlyFans") {
        return -1
      } else if (b.platform === "OnlyFans") {
        return 1
      }
      return 0
    })
    return profileCollection.map((profile) => {
      return (
        <Paper
          elevation={1}
          key={profile.url}
          onClick={() => scrollToPromo(profile.platform as Brands, profile.url)}
          // onClick={() => linkToSocialMedia(profile.url)}
        >
          <Stat>
            <div className={"stat-header"}>
              <img
                width={16}
                height={16}
                src={getLogo(profile.platform, false)}
              />
              <Typography variant={"caption"} fontWeight={"bold"} noWrap>
                {profile.platform}
              </Typography>
            </div>
            <Typography
              noWrap
              sx={{ color: theme.palette.text.secondary }}
              variant={"caption"}
            >
              {profile.stat}
            </Typography>
          </Stat>
        </Paper>
      )
    })
  }

  return <StyledStatsDisplay>{getSortedStats()}</StyledStatsDisplay>
}

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 4px;
  width: 100%;
  min-width: fit-content;
  cursor: pointer;

  .stat-header {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`

const StyledStatsDisplay = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  gap: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: flex-start;
`
