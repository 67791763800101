import styled from "@emotion/styled"
import {
  Box,
  Fab,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { TabPanel } from "../../Atoms/TabPanel"
import { SelectProfileRadioButtons } from "../SelectProfileRadioButtons"
import { UserContext } from "../../../contexts/user"
import { get, patch } from "../../../utils/requests"
import {
  IPromoDateSelectorProps,
  PromoDateSelector,
} from "../../Atoms/Profile/PromoDateSelector"
import {
  AvailablePromos,
  IAvailablePromosProps,
  IPromoHeaderStat,
} from "../../Molecules/Profile/AvailablePromos"
import { IPhoto } from "../../../utils/photos"
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined"
import { ImageViewer } from "../../Atoms/ImageViewer"
import { parsePhotos } from "../../../utils/parsers"
import { AccountHeader } from "../../Molecules/AccountHeader"
import { IRatingProps } from "../../Atoms/Rating"
import { PopUp } from "../../Atoms/PopUp"
import { PlayArrow, VisibilityOff } from "@mui/icons-material"
import { PrimaryButton } from "../../Atoms/Buttons"
import Link from "next/link"
import { ISocialStat } from "../../Atoms/Profile/StatsDisplay"
import { ITag } from "../../Atoms/OnBoarding/Tags"
import { Brands } from "../../Atoms/Profile/Header/Logo"
import { RequestErrorModal } from "../../Molecules/RequestErrorModal"
import { BlossmImage } from "../../Atoms/BlossmImage"
import { roleOptions } from "../../Atoms/ProfileRoleBadge"
import { ManageMedia } from "./Media/ManageMedia"
import { MoveMediaDialog } from "./Media/MoveMediaDialog"
import { IFolder, parseFolders } from "./Media/MediaFolders"

export interface IProfileViewProps {
  profileImage: string
  accountName: string
  accountHandle: string
  isPersonalProfile: boolean
  rating: IRatingProps
  blurb: string[]
  role: roleOptions
  promoDates?: IPromoDateSelectorProps
  availablePromos?: IAvailablePromosProps[]

  deletePromo(uuid: string): void

  name: string
  verified: boolean
  isCustomerSupport: boolean
  isStaff: boolean
  accountSetupProgress: number
  socialStats: { stats: ISocialStat[]; profiles: ISocialStat[] }
  promoHeaderStats?: IPromoHeaderStat[]
  tags: ITag[]
  online: boolean
  profileUuid?: string
}

export const ProfileView = ({
  isPersonalProfile,
  profileImage,
  accountName,
  accountHandle,
  rating,
  blurb,
  promoDates,
  availablePromos,
  deletePromo,
  name,
  verified,
  isCustomerSupport,
  isStaff,
  accountSetupProgress,
  socialStats,
  promoHeaderStats,
  tags,
  online,
  profileUuid,
  role,
}: IProfileViewProps): React.JSX.Element => {
  const [value, setValue] = useState(0)
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const { context } = useContext(UserContext)
  const [openManageMedia, setOpenManageMedia] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>()
  const [selectedPosition, setSelectedPosition] = useState(0)
  const [photos, setPhotos] = useState<IPhoto[]>([])
  const theme = useTheme()
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")
  const [imageViewerOpen, setImageViewerOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const earliestAvailableDate = useMemo(
    () => promoDates?.dates[0] ?? new Date(),
    [promoDates]
  )
  const [moveFolderOpen, setMoveFolderOpen] = useState(false)
  const [folders, setFolders] = useState<IFolder[]>([])
  const [activeFolder, setActiveFolder] = useState<IFolder | undefined | null>(
    undefined
  )
  const [placeholderCount, setPlaceholderCount] = useState<number>(0)

  const eligiblePromos = useMemo(
    () =>
      isPersonalProfile || !context.userRole
        ? availablePromos
        : availablePromos?.filter((promos) => {
            return promos.promos.some((promo) =>
              promo.availability.includes(
                context.userRole === roleOptions.agency
                  ? roleOptions.independentCreator
                  : context.userRole
              )
            )
          }),
    [availablePromos]
  )
  const profileViewContainer = useRef<HTMLDivElement>(null)
  const [refs, setRefs] = useState<React.RefObject<HTMLDivElement>[]>([])

  useEffect(() => {
    setRefs(eligiblePromos?.map(() => createRef<HTMLDivElement>()) || [])
  }, [eligiblePromos])

  function linkToSocialMedia(url: string): void {
    window.open(url, "_blank")
  }

  function scrollToPromo(platform: Brands, url: string): void {
    const username = url.replace(/.*\//, "")
    const brandedPromoIndex = eligiblePromos?.findIndex(
      (promo) =>
        promo.brand === platform.toString() && promo.userName === username
    )
    if (brandedPromoIndex === undefined || brandedPromoIndex === -1) {
      linkToSocialMedia(url)
      return
    }
    const containerRef = profileViewContainer?.current
    const selectedRef = refs[brandedPromoIndex].current

    if (!selectedRef || !containerRef) {
      linkToSocialMedia(url)
      return
    }
    containerRef.scrollTo({
      behavior: "smooth",
      top: selectedRef.offsetTop,
    })
  }

  function getProfilePhotos(): void {
    get(`/photo_video/photo/list/${name}/`)
      .then((response) => {
        setPhotos(parsePhotos(response.data))
      })
      .catch((error) => console.error(error))
  }

  function onMediaDelete(): void {
    getProfilePhotos()
    setImageViewerOpen(false)
    setSnackbarMessage("Media deleted!")
  }

  function onMediaUpdate(message: string): void {
    getProfilePhotos()
    setSnackbarMessage(message)
  }

  function handlePhotoSelection(index: number): void {
    setCurrentImageIndex(index)
    setImageViewerOpen(true)
  }

  const handleDrawerOpen = (): void => {
    setDrawerOpen(!drawerOpen)
  }

  useEffect(() => {
    getProfilePhotos()
  }, [name])

  let uploadButton = null

  if (isPersonalProfile) {
    uploadButton = (
      <>
        <div className="fab-button-container">
          <Fab
            className={"upload-button"}
            variant={"extended"}
            color={"primary"}
            style={{
              display: "flex",
            }}
            onClick={() => {
              setActiveFolder(undefined)
              setOpenManageMedia(true)
            }}
          >
            <ImageOutlinedIcon sx={{ marginRight: "8px" }} />
            Manage
          </Fab>
        </div>
      </>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getFolders(): Promise<void> {
    return get("/photo_video/folder/")
      .then((response) => {
        setFolders(parseFolders(response.data))
      })
      .catch((error) => console.error(error))
  }

  function openMoveDialog(): void {
    getFolders().then(() => {
      setMoveFolderOpen(true)
      setImageViewerOpen(false)
    })
  }

  function moveSelected(folder?: IFolder): void {
    const formData = new FormData()
    formData.append("folder_name", folder ? folder.name : "")
    patch(
      `/photo_video/photo/move/${photos[currentImageIndex].id}/`,
      formData
    ).then(() => {
      getProfilePhotos()
      getFolders()
      setSnackbarMessage(
        `Media moved to ${folder ? folder.name : "Blossm Gallery"}`
      )
      setMoveFolderOpen(false)
    })
  }

  const hasPromos =
    promoDates &&
    eligiblePromos &&
    earliestAvailableDate &&
    eligiblePromos.length > 0 &&
    eligiblePromos.some((promo) => promo.promos.length)
  const showPromoDateSelector = hasPromos && !isPersonalProfile && false
  return (
    <StyledProfileContainer translate={"no"}>
      <div className="profile-view-inner-container">
        <StyledProfileView theme={theme} ref={profileViewContainer}>
          <AccountHeader
            tags={tags}
            scrollToPromo={scrollToPromo}
            isPersonalAccount={isPersonalProfile}
            openProfileSelection={() => handleDrawerOpen()}
            headerImage={profileImage}
            accountName={accountName}
            accountHandle={accountHandle}
            rating={rating}
            socialStats={socialStats}
            blurb={blurb}
            verified={verified}
            accountSetupCompletionPercentage={accountSetupProgress}
            isCustomerSupport={isCustomerSupport}
            isStaff={isStaff}
            online={online}
            profileUuid={profileUuid}
            role={role}
          />
          <div className="body">
            <Tabs
              sx={{ boxShadow: "inset -1px -5px 0px -2px rgba(0, 0, 0, 0.22)" }}
              variant="fullWidth"
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
            >
              <Tab label="PRICING" />
              <Tab label="MEDIA" />
            </Tabs>
            <TabPanel value={value} index={0} padding={"16px"}>
              <div className={"pricing-tab-panel"}>
                {hasPromos ? (
                  <>
                    {showPromoDateSelector && (
                      <PromoDateSelector
                        {...promoDates}
                        setDate={(date) => {
                          setSelectedDate(date)
                        }}
                        currentDate={selectedDate ?? earliestAvailableDate}
                        currentPosition={selectedPosition}
                        setPosition={(position) =>
                          setSelectedPosition(position)
                        }
                      />
                    )}
                    {showPromoDateSelector && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          textAlign: "center",
                        }}
                      >
                        {isPersonalProfile ? (
                          <Typography variant={"h6"}>
                            Promotions for Sale
                          </Typography>
                        ) : (
                          <Typography variant={"body1"}>
                            Promos Available{" "}
                            {selectedDate !== undefined
                              ? selectedDate.toLocaleDateString("en-US", {
                                  month: "long",
                                  day: "numeric",
                                })
                              : earliestAvailableDate.toLocaleDateString(
                                  "en-US",
                                  {
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                          </Typography>
                        )}
                      </Box>
                    )}
                    {eligiblePromos.map((promo, index) => {
                      if (promo.promos.length > 0) {
                        const link = socialStats.profiles.find(
                          (p) =>
                            p.stat === promo.userName &&
                            p.platform === promo.brand
                        )?.url
                        return (
                          <span
                            ref={refs[index]}
                            key={promo.userName + promo.brand}
                          >
                            <AvailablePromos
                              linkToSocials={link}
                              socialStats={promoHeaderStats}
                              {...promo}
                              deletePromo={deletePromo}
                              selectedDate={
                                selectedDate ?? earliestAvailableDate
                              }
                              isPersonalProfile={isPersonalProfile}
                              blossmProfileName={accountName}
                            />
                          </span>
                        )
                      } else {
                        return <React.Fragment key={index} />
                      }
                    })}
                  </>
                ) : (
                  <>
                    {isPersonalProfile ? (
                      <div className="create-your-promo-content">
                        <Typography
                          variant={"h6"}
                          color={theme.palette.text.primary}
                          sx={{ marginBottom: "24px" }}
                        >
                          <strong>
                            You don’t have any promos yet. <br></br>
                            Create your first promo!
                          </strong>
                        </Typography>
                        <Link href={"/create"}>
                          <PrimaryButton>Create a Promo</PrimaryButton>
                        </Link>
                      </div>
                    ) : (
                      <Typography
                        variant={"subtitle2"}
                        color={theme.palette.text.secondary}
                      >
                        No promos available to you.
                      </Typography>
                    )}
                  </>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <>
                <div className="note-container">
                  <Typography
                    variant={"subtitle2"}
                    color={theme.palette.text.secondary}
                  >
                    {isPersonalProfile &&
                      "Blossm prohibits nudity in public media. Any nude media you add should be set to private. Private media can still be sent in chat or when ordering promo."}
                  </Typography>
                </div>
                {photos.length > 0 ? (
                  <div className={"uploaded-images"}>
                    {photos.map(
                      (photo, index) =>
                        photo.thumbnailUrl && (
                          <div
                            key={index}
                            className={"uploaded-image-container"}
                            onClick={() => handlePhotoSelection(index)}
                          >
                            <BlossmImage
                              alt={"thumbnail url"}
                              src={photo.thumbnailUrl}
                              borderRadius={8}
                            />

                            {photo.isVideo && (
                              <Fab
                                size="medium"
                                color="primary"
                                sx={{
                                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                                  },
                                  zIndex: 0,
                                }}
                              >
                                <PlayArrow
                                  sx={{
                                    color: "rgba(255, 255, 255, 0.7)",
                                    fontSize: "30px",
                                  }}
                                />
                              </Fab>
                            )}

                            {isPersonalProfile && !photo.isPublic && (
                              <Tooltip title="Only visible to you" arrow>
                                <VisibilityOff
                                  sx={{
                                    color: "rgba(255, 255, 255, 0.9)",
                                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                                    borderRadius: "50%",
                                    padding: "4px",
                                    fontSize: "30px",
                                    position: "absolute",
                                    top: "6px",
                                    left: "6px",
                                  }}
                                />
                              </Tooltip>
                            )}
                          </div>
                        )
                    )}
                  </div>
                ) : (
                  <Typography
                    variant={"subtitle2"}
                    color={theme.palette.text.secondary}
                  >
                    No media to display.
                  </Typography>
                )}
                {uploadButton}
              </>
            </TabPanel>
          </div>
          <PopUp open={drawerOpen} handleToggle={setDrawerOpen}>
            <SelectProfileRadioButtons setDrawerOpen={setDrawerOpen} />
          </PopUp>
          <ImageViewer
            onDelete={isPersonalProfile ? onMediaDelete : undefined}
            onUpdate={
              isPersonalProfile
                ? (message) => onMediaUpdate(message)
                : undefined
            }
            images={photos}
            open={imageViewerOpen}
            onClose={() => setImageViewerOpen(false)}
            position={currentImageIndex}
            setPosition={setCurrentImageIndex}
            isMediaProfile={true}
            snackbarMessage={snackbarMessage}
            setSnackbarMessage={setSnackbarMessage}
            handleMove={() => {
              openMoveDialog()
            }}
            currentFolder={null}
          />
        </StyledProfileView>
      </div>
      <RequestErrorModal />

      <PopUp
        open={openManageMedia}
        handleToggle={(open) => {
          setOpenManageMedia(open)
        }}
      >
        <ManageMedia
          profileName={name}
          folders={folders}
          setFolders={setFolders}
          setOpenManageMedia={setOpenManageMedia}
          getProfilePhotos={getProfilePhotos}
          activeFolder={activeFolder}
          setActiveFolder={setActiveFolder}
          placeholderCount={placeholderCount}
          setPlaceholderCount={setPlaceholderCount}
          isEditing={true}
        />
      </PopUp>

      <MoveMediaDialog
        open={moveFolderOpen}
        setMoveFolderOpen={setMoveFolderOpen}
        handleMove={moveSelected}
        currectFolder={null}
        folders={folders}
      />
    </StyledProfileContainer>
  )
}

const StyledProfileContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .profile-view-inner-container {
    height: inherit;
  }
`

const StyledProfileView = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .image-list {
      height: 100%;
    }
  }

  .upload-button {
    color: white;
  }

  .photo-control-bar {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 10px 10px;

    .icon {
      cursor: pointer;
      color: white;
    }
  }

  .media-actions {
    position: absolute;
    height: 146px;
    width: 100%;
    bottom: 0;
  }

  .delete-button {
    width: 100%;
    border: 0px;
    justify-content: flex-start;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }

  .profile-select-modal-content {
    background-color: white;
    align-self: center;
    max-width: 400px;
    transform: translate(-50%, -50%);
  }

  .drawer-content {
    padding: 10px;
  }

  .create-promo-button {
    height: 50px;
    position: absolute;
    right: 20px;
    bottom: 10px;
    width: 115px;
  }

  .pricing-tab-panel {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .fab-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: sticky;
    bottom: 21px;
    width: 100%;
  }

  .uploaded-images {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .uploaded-image-container {
      height: 200px;
      width: 200px;
      position: relative;

      .uploaded-image {
        border-radius: 8px;
        cursor: pointer;
      }

      ${(props) => props.theme.breakpoints.down("md")} {
        height: 150px;
        width: 150px;
      }
    }
  }

  .create-your-promo-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 108px;
    text-align: center;
  }

  .note-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
  }
`
