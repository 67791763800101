import styled from "@emotion/styled"
import React, { useContext, useState } from "react"
import { Theme, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Verified } from "../Atoms/Verified"
import { IRatingProps, Rating } from "../Atoms/Rating"
import { ArrowDropDown } from "@mui/icons-material"
import { Brands } from "../Atoms/Profile/Header/Logo"
import Link from "next/link"
import { ActionTypes, UserContext } from "../../contexts/user"
import { useRouter } from "next/router"
import { ContinueOnBoarding } from "../Atoms/ContinueOnBoarding"
import { patch } from "../../utils/requests"
import { ChatActionTypes, ChatContext } from "../../contexts/chat"
import { InverseButton } from "../Atoms/Buttons"
import { PrimaryColorAccountCircle } from "../Atoms/Icons/PrimaryColorIcons"
import { StaffLinks } from "./StaffLinks"
import { ISocialStat, StatsDisplay } from "../Atoms/Profile/StatsDisplay"
import { ITag } from "../Atoms/OnBoarding/Tags"
import { StaffBadge } from "../Atoms/StaffBadge"
import { Avatar } from "../Atoms/Avatar"
import ProfileRoleBadge, {
  roleBadgeSizeOptions,
  roleOptions,
} from "../Atoms/ProfileRoleBadge"

export interface IProfile {
  profileName: string
  platform: Brands
  url: string
}

export interface IAccountHeaderProps {
  headerImage: string
  accountName: string
  accountHandle: string
  rating: IRatingProps
  blurb: string[]
  openProfileSelection: () => void
  isPersonalAccount: boolean
  verified: boolean
  isCustomerSupport: boolean
  isStaff: boolean
  accountSetupCompletionPercentage: number
  socialStats: { stats: ISocialStat[]; profiles: ISocialStat[] }
  scrollToPromo: (brand: Brands, url: string) => void
  tags: ITag[]
  online: boolean
  role: roleOptions
  profileUuid?: string
}

export const AccountHeader = ({
  headerImage,
  accountName,
  accountHandle,
  rating,
  blurb,
  openProfileSelection,
  isPersonalAccount,
  verified,
  isCustomerSupport,
  isStaff,
  accountSetupCompletionPercentage,
  socialStats,
  scrollToPromo,
  tags,
  online,
  role,
}: IAccountHeaderProps): React.JSX.Element => {
  const theme = useTheme()
  const { context, dispatch } = useContext(UserContext)
  const { dispatch: chatDispatch } = useContext(ChatContext)
  const router = useRouter()
  const [showBlurb, setShowBlurb] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"))
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isDefaultAvatar = headerImage?.match(/\/images\/avatar_[0-9]\.png/g)

  function getBlurb(): JSX.Element {
    const maxBlurbLength = isMobile && !isTablet ? 140 : 275
    if (blurb.length === 1) {
      return (
        <span>
          <Typography variant={"body2"}>
            {blurb[0].slice(0, showBlurb ? blurb[0].length : maxBlurbLength) +
              " "}
            {blurb[0].length > maxBlurbLength && (
              <SeeMore theme={theme} onClick={() => setShowBlurb(!showBlurb)}>
                Show {showBlurb ? "Less" : "More"}
              </SeeMore>
            )}
          </Typography>
        </span>
      )
    } else if (blurb.length !== 0) {
      return (
        <MultiLineBlurb>
          {blurb.slice(0, showBlurb ? blurb.length : 2).map((text, index) => {
            return (
              <Typography variant={"body2"} key={index}>
                {text}
              </Typography>
            )
          })}
          <Typography variant={"body2"}>
            {!showBlurb && <span>...</span>}
            <SeeMore theme={theme} onClick={() => setShowBlurb(!showBlurb)}>
              Show {showBlurb ? "Less" : "More"}
            </SeeMore>
          </Typography>
        </MultiLineBlurb>
      )
    } else {
      return <></>
    }
  }

  return (
    <StyledAccountHeader theme={theme}>
      <StaffLinks
        profileUsername={accountHandle.replace("@", "")}
        profileIsStaff={isStaff}
        isPersonalProfile={isPersonalAccount}
        userContext={context}
      />
      <StyledHeaderUpperLevel
        isPersonalAccount={isPersonalAccount}
        theme={theme}
        isMobile={isMobile}
      >
        <div className={"account-header-body"}>
          <div className={"header-image-container"}>
            {!headerImage || isDefaultAvatar ? (
              <div className="default-avatar">
                <PrimaryColorAccountCircle
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
            ) : (
              <Avatar online={online} profileImage={headerImage} size={"150"} />
            )}
          </div>
          <div className={"upper-header-main-body"}>
            <div className={"profile-info-row"}>
              <div className={"name-stack"}>
                <div
                  className={"name-stack-row"}
                  onClick={() => {
                    if (isPersonalAccount) {
                      openProfileSelection()
                    }
                  }}
                >
                  <Typography
                    variant={"subtitle1"}
                    noWrap
                    sx={{
                      maxWidth: isMobile
                        ? isExtraSmall
                          ? "120px"
                          : "200px"
                        : "100%",
                    }}
                  >
                    {accountName}
                  </Typography>
                  {verified && <Verified />}
                  {role === roleOptions.brandOrBusiness && (
                    <ProfileRoleBadge
                      roleOption={role as roleOptions}
                      size={roleBadgeSizeOptions.small}
                      showLabel={false}
                    />
                  )}
                  {isCustomerSupport && (
                    <StaffBadge expand={true} sx={{ top: "0px" }} />
                  )}
                  {isPersonalAccount && <ArrowDropDown />}
                </div>
                <Typography
                  variant={"body2"}
                  color={theme.palette.primary.main}
                  noWrap
                  sx={{
                    maxWidth: isMobile
                      ? isExtraSmall
                        ? "180px"
                        : "200px"
                      : "100%",
                  }}
                >
                  {accountHandle}
                </Typography>
                <Rating
                  precision
                  {...rating}
                  onClick={() =>
                    router.push(
                      `/review/summary/${accountHandle.replace("@", "")}`
                    )
                  }
                />
              </div>
              <div className={"account-header-button-row"}>
                {isPersonalAccount ? (
                  <>
                    <Link href={"/edit-profile"}>
                      <InverseButton
                        variant={"outlined"}
                        sx={{ width: "120px" }}
                      >
                        Edit Profile
                      </InverseButton>
                    </Link>
                  </>
                ) : (
                  <>
                    {/*<Button size={"medium"} sx={{height: "40px", color: "white"}}  onClick={() => handleFollowButton()}>Follow</Button>*/}
                    <InverseButton
                      size={"medium"}
                      sx={{ height: "40px" }}
                      variant={"outlined"}
                      onClick={() => {
                        if (!context.isAuthenticated) {
                          router.push("/login")
                        } else {
                          // We don't have the drawer on mobile so we need to move to /messages page instead.
                          // Don't remove this!!
                          if (isMobile) {
                            router.push(`/messages/${accountHandle}`)
                          } else {
                            chatDispatch({
                              type: ChatActionTypes.ChatWithUser,
                              payload: accountHandle,
                            })
                          }
                        }
                      }}
                    >
                      Message
                    </InverseButton>
                  </>
                )}
              </div>
            </div>
            {socialStats && !isMobile && (
              <StatsDisplay {...socialStats} scrollToPromo={scrollToPromo} />
            )}
          </div>
        </div>
      </StyledHeaderUpperLevel>
      {socialStats && isMobile && (
        <StatsDisplay {...socialStats} scrollToPromo={scrollToPromo} />
      )}
      <TagsContainer>
        {tags.map((tag) => {
          return (
            <Tag theme={theme} key={tag.id}>
              <Typography
                noWrap
                variant={"caption"}
                color={theme.palette.primary.main}
              >
                {tag.tag}
              </Typography>
            </Tag>
          )
        })}
      </TagsContainer>
      <StyledBlurb>{getBlurb()}</StyledBlurb>
      {isPersonalAccount &&
        accountSetupCompletionPercentage !== 100 &&
        context.showOnboardingBar && (
          <StyledOnboardingContainer>
            <ContinueOnBoarding
              progress={accountSetupCompletionPercentage}
              hidePrompt={() => {
                patch(`/show-onboarding-bar/${context.userUuid}/`, {
                  showOnboardingBar: false,
                })
                  .then(() => {
                    dispatch({
                      type: ActionTypes.ShowOnboardingBar,
                      payload: {
                        showOnboardingBar: false,
                      },
                    })
                  })
                  .catch(() => {
                    console.warn("Show Onboarding Update Fail")
                  })
              }}
            />
          </StyledOnboardingContainer>
        )}
    </StyledAccountHeader>
  )
}

const TagsContainer = styled.div`
  display: flex;
  padding: 8px;
  gap: 8px;
  max-width: 100%;
  overflow-x: auto;
`

const Tag = styled.div<{ theme: Theme }>`
  padding: 4px 9px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 15px;
`

const StyledOnboardingContainer = styled.div`
  padding-top: 16px;
`

const MultiLineBlurb = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`

const StyledBlurb = styled.div`
  margin-top: 16px;
`

const SeeMore = styled.span<{ theme: Theme }>`
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
`

const StyledHeaderUpperLevel = styled.div<{
  theme: Theme
  isMobile: boolean
  isPersonalAccount: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  gap: 16px;
  width: 100%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: 5px;
  }

  .stats-block {
    overflow: hidden;
    width: 100%;
  }

  .account-header-body {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 16px;
  }

  .lower-header-body {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .alt-profile-summary {
      display: flex;
      gap: 4px;
      align-items: center;
      margin-top: auto;
      cursor: pointer;
    }
  }

  .header-image-container {
    overflow: hidden;
    border-radius: 10px;
    min-height: 150px;
    min-width: 150px;
    max-width: 150px;
    max-height: 150px;
    display: flex;

    span {
      border-radius: 10px;
    }

    .default-avatar {
      max-width: 174px;
      height: max-content;
    }
  }

  .upper-header-main-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    .profile-info-row {
      display: flex;
      justify-content: space-between;

      ${(props) => props.theme.breakpoints.down("sm")} {
        flex-direction: column;
      }

      width: 100%;
      padding-bottom: 8px;
    }

    .account-header-button-row {
      margin-top: 10px;
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: auto;
    }
  }

  .name-stack {
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      gap: 4px;
    }

    .name-stack-row {
      display: flex;
      gap: 5px;

      ${(props) => props.theme.breakpoints.down("xs")} {
        gap: 3px;
      }

      align-items: center;
      cursor: ${(props) => (props.isPersonalAccount ? "pointer" : "default")};
    }
  }
`

const StyledAccountHeader = styled.div<{ theme: Theme }>`
  padding: 32px;
  width: 100%;
  max-width: 100%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 16px;
  }

  display: flex;
  flex-direction: column;

  .mobile-header-icon-container {
    padding-bottom: 8px;
    margin-left: auto;
    cursor: pointer;
  }

  .mobile-profile-bar {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;

    .alt-profile-summary {
      display: flex;
      gap: 3px;
      align-items: center;
      cursor: pointer;
    }
  }
`
