import styled from "@emotion/styled"
import {
  alpha,
  Button,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material"
import { ArrowForward } from "@mui/icons-material"
import { Colors } from "../../styles/Colors"
import Link from "next/link"

export interface IContinueOnboardingProps {
  progress: number
  hidePrompt: () => void
}

export const ContinueOnBoarding = ({
  progress,
  hidePrompt,
}: IContinueOnboardingProps): JSX.Element => {
  const theme = useTheme()
  return (
    <StyledContinueOnboarding>
      <Link href={"/onboarding"}>
        <div className={"continue-on-boarding-top-row"}>
          <Typography variant={"h6"}>Continue Onboarding</Typography>
          <ArrowForward
            sx={{ color: alpha(Colors.black, 0.54), cursor: "pointer" }}
          />
        </div>
      </Link>
      <Typography variant={"body2"} color={theme.palette.text.secondary}>
        Complete all Onboarding steps to buy and sell promo without any
        restrictions.
      </Typography>
      <div className={"on-boarding-progress-bar"}>
        <LinearProgress
          sx={{ width: "100%" }}
          variant={"determinate"}
          value={progress}
        />
        <Typography variant={"body2"} color={theme.palette.text.secondary}>
          {progress}%
        </Typography>
      </div>
      <Button
        variant={"text"}
        size={"small"}
        sx={{ width: "fit-content" }}
        onClick={() => hidePrompt()}
      >
        Hide this prompt
      </Button>
    </StyledContinueOnboarding>
  )
}

const StyledContinueOnboarding = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;

  .continue-on-boarding-top-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .on-boarding-progress-bar {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
  }

  .hide-link {
    cursor: pointer;
  }
`
