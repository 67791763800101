import { useContext, useEffect, useState, useCallback } from "react"
import { ArgJSONMap } from "../../utils/argjsonmap"
import { INamePlateProps } from "../../components/Molecules/Profile/NamePlate"
import { ProfileView } from "../../components/Organisms/Profile/ProfileView"
import {
  createSsrRequestConfig,
  get,
  getBackendUrl,
} from "../../utils/requests"
import { useRouter } from "next/router"
import { UserContext } from "../../contexts/user"
import {
  IAuthProfileAPIResponse,
  parseAuthProfileGetResponse,
} from "../../utils/parsers"
import { IProfilePromoProps } from "../../components/Atoms/Profile/ProfilePromo"
import { IPromoTier } from "../../components/Molecules/Promo/CreatePromo"
import {
  IAvailablePromosProps,
  IPromoHeaderStat,
} from "../../components/Molecules/Profile/AvailablePromos"
import { Layout } from "../../components/Organisms/Pages/Layout/Layout"
import { ICustomPageProps, NextPageWithLayout } from "../_app"
import {
  coerceDateStringToClientTimeZone,
  forceDateStringToDeviceTimeZone,
} from "../../utils/dates"
import { validateUrlParam } from "../../utils/paramValidation"
import { AxiosRequestConfig } from "axios"
import { GetServerSidePropsContext } from "next"
import { SEO } from "../../components/Organisms/SEO"
import { parseLocaleNumberShort } from "../../utils/numberTruncate"
import { Brands } from "../../components/Atoms/Profile/Header/Logo"
import { ISocialStat } from "../../components/Atoms/Profile/StatsDisplay"
import { ITag } from "../../components/Atoms/OnBoarding/Tags"
import {
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"

import BlossmLogoSmall from "../../components/assets/BlossmLogoSmall.svg"
import {
  LinkRequirement,
  MediaContentType,
  MediaRequirement,
} from "../../components/Atoms/Promo/PromoRequirements"
import { roleOptions } from "../../components/Atoms/ProfileRoleBadge"

interface IProfilePageProps extends ICustomPageProps {
  name: string
}

const ProfilePage: NextPageWithLayout = ({ name }: IProfilePageProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [myProfile, setMyProfile] = useState<IAuthProfileAPIResponse>()
  const [nameplateProps, setNameplateProps] =
    useState<Omit<INamePlateProps, "userProfile">>()
  const [socialStats, setSocialStats] = useState<ISocialStat[]>([])
  const [promoHeaderStats, setPromoHeaderStats] = useState<IPromoHeaderStat[]>(
    []
  )
  const [socialAccounts, setSocialAccounts] = useState<ISocialStat[]>([])
  const [promos, setPromos] = useState<IAvailablePromosProps[]>([])
  const [availableDates, setAvailableDates] = useState<Date[]>([])
  const [hasDefaultProfileImage, setHasDefaultProfileImage] = useState(false)
  const [hasSocialMediaAccounts, setHasSocialMediaAccounts] = useState(false)
  const router = useRouter()

  const { context } = useContext(UserContext)
  const [tagsCollection, setTagsCollection] = useState<ITag[]>([])
  const [userSelectedTags, setUserSelectedTags] = useState<number[]>([])

  const isPersonalAccount = name === context.profileName
  const description_meta =
    (myProfile?.aboutMe?.join(" ").trim() ? " — " : "") +
    "View on Blossm, the trusted promo marketplace for paid promotions and shoutouts across OnlyFans, Instagram, Fansly, X/Twitter and more!"

  const deletePromo = useCallback((uuid: string): void => {
    setPromos((prevState) => {
      return prevState.map((promoCategory) => {
        return {
          ...promoCategory,
          promos: promoCategory.promos.filter((promo) => promo.uuid !== uuid),
        }
      })
    })
  }, [])

  function getAccountSetupProgress(
    userRole: string,
    phoneNumber: string,
    isPhoneValidated: boolean
  ): number {
    const validationArray = [
      !!userRole,
      hasDefaultProfileImage,
      phoneNumber !== "" && isPhoneValidated,
      hasSocialMediaAccounts,
      context.userIdVerified,
      context.tags.length > 0,
    ]
    const onboardingPagesLength = validationArray.length
    const finishedLength = validationArray.filter(
      (item) => item === true
    ).length
    const multiplyBy = 100 / onboardingPagesLength

    return Math.floor(finishedLength * multiplyBy)
  }

  useEffect(() => {
    if (name) {
      setPromoHeaderStats([])
      setSocialStats([])
      let accountUrl = `/socials/account/?name=${encodeURIComponent(name)}`

      if (isPersonalAccount) {
        accountUrl += "&include_unverified=true"
      }
      get(accountUrl)
        .then((response) => {
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          setSocialAccounts(
            response.data.map((d: any) => {
              return {
                platform: d.social_media_type,
                stat: d.social_account_username,
                url: d.user_homepage_url,
                verified: d.is_verified,
              }
            })
          )
          setHasSocialMediaAccounts(response.data.length > 0)
        })
        .catch((error) => console.error(error))

      get(`/auth/profile/?name=${encodeURI(name as string).toLowerCase()}`)
        .then((response) => {
          if (response.data.length === 0) {
            router.replace("/404", `/profile/${name}`)
            return
          }
          const profile = parseAuthProfileGetResponse(
            ArgJSONMap.fromParsedJson(
              response.data.length > 0 ? response.data[0] : {}
            )
          )
          setMyProfile(profile)
          setHasDefaultProfileImage(profile.profileAvatarSet)
          setNameplateProps({
            // following: true,
            verified: profile.verified,
            avatar: {
              online: profile.online,
              profileImage: profile.avatarCropped,
              size: "Medium",
            },
            profile: {
              align: "Left",
              name: profile.displayName,
              handle: `@${profile.name}`,
              rating: {
                rating: profile.rating,
                numberOfReviews: profile.numReviews,
              },
              verified: profile.verified,
              isCustomerSupport: profile?.isCustomerSupport,
              isStaff: profile.isStaff,
              avatar: {
                online: profile.online,
                profileImage: profile.avatarCropped,
                size: "Medium",
              },
            },
          })
          setUserSelectedTags(profile.tags)

          get(
            `/socials/details/?profile_uuid=${encodeURIComponent(profile.uuid)}`
          )
            .then((response) => {
              response.data.forEach(
                (detail: {
                  platform: string
                  top_rated: string
                  fans: number
                  followers: number
                  following: number
                  updated_at: string
                  is_expired: boolean
                  expire_days: number
                  url: string
                  active: boolean
                  social_account_username: string
                }) => {
                  const currentStats: IPromoHeaderStat[] = []
                  if (detail.active) {
                    if (detail.platform.toLowerCase() === "onlyfans") {
                      currentStats.push({
                        isExpired: detail.is_expired,
                        brand: detail.platform as Brands,
                        social_account_username: detail.social_account_username,
                        header: (
                          parseFloat(detail.top_rated) / 100
                        ).toLocaleString("en-US", {
                          style: "percent",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        }),
                        subtitle: "Top %",
                        lastUpdated: new Date(detail.updated_at),
                        expireDays: detail.expire_days,
                      })
                      currentStats.push({
                        isExpired: detail.is_expired,
                        brand: detail.platform as Brands,
                        social_account_username: detail.social_account_username,
                        header: parseLocaleNumberShort(detail.fans),
                        subtitle: "Subscribers",
                        lastUpdated: new Date(detail.updated_at),
                        expireDays: detail.expire_days,
                      })
                      currentStats.push({
                        isExpired: detail.is_expired,
                        brand: detail.platform as Brands,
                        social_account_username: detail.social_account_username,
                        header: parseLocaleNumberShort(detail.following),
                        subtitle: "Following",
                        lastUpdated: new Date(detail.updated_at),
                        expireDays: detail.expire_days,
                      })
                    } else {
                      currentStats.push({
                        isExpired: detail.is_expired,
                        brand: detail.platform as Brands,
                        social_account_username: detail.social_account_username,
                        header: parseLocaleNumberShort(detail.followers),
                        subtitle:
                          detail.platform.toLowerCase() === "reddit"
                            ? "Karma"
                            : detail.platform.toLowerCase() === "youtube" ||
                              detail.platform.toLowerCase() === "snapchat"
                            ? "Subscribers"
                            : "Followers",
                        lastUpdated: new Date(detail.updated_at),
                        expireDays: detail.expire_days,
                      })
                    }
                  }

                  setPromoHeaderStats((prevState) => [
                    ...prevState,
                    ...currentStats,
                  ])
                }
              )

              setSocialStats(
                response.data.map(
                  (detail: {
                    platform: string
                    top_rated: string
                    fans: number
                    followers: number
                    following: number
                    url: string
                    active: boolean
                  }) => {
                    let label = ""

                    if (detail.active) {
                      if (detail.platform.toLowerCase() === "onlyfans") {
                        label = `Top ${(
                          parseFloat(detail.top_rated) / 100
                        ).toLocaleString("en-US", {
                          style: "percent",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })} ${parseLocaleNumberShort(detail.fans)} Subs`
                      } else {
                        label = `${parseLocaleNumberShort(detail.followers)}${
                          detail.platform.toLowerCase() === "reddit"
                            ? " Karma"
                            : detail.platform.toLowerCase() === "youtube" ||
                              detail.platform.toLowerCase() === "snapchat"
                            ? " Subs"
                            : " Followers"
                        } `
                      }
                    }

                    return {
                      platform: detail.platform,
                      url: detail.url,
                      stat: label,
                    }
                  }
                )
              )
            })
            .catch((error) => console.error(error))

          get(`/promo_dates/?profile_uuid=${profile.uuid}`)
            .then((response) => {
              const dates: Date[] = []
              response.data.forEach((day: string) => {
                const today = new Date()
                today.setHours(0, 0, 0, 0)
                const date = coerceDateStringToClientTimeZone(day)
                if (date >= today) {
                  dates.push(date)
                }
              })
              setAvailableDates(dates)
            })
            .catch((errors) => console.error(errors))

          get(`/promo_tier_query/?profile_uuid=${profile.uuid}`)
            .then((response) => {
              const parsedPromos: IAvailablePromosProps[] = []
              Object.keys(response.data).forEach(
                (key: string, index: number) => {
                  const profiles = response.data[key]
                  const socials = profiles.map((profile: unknown) => {
                    return ArgJSONMap.fromParsedJson(profile)
                  })

                  socials.forEach((social: ArgJSONMap) => {
                    const promos: ArgJSONMap[] = social.getList("promos") ?? []
                    const availablePromos = promos.map(
                      (promo: ArgJSONMap): IProfilePromoProps => {
                        const rawPromoTiers = promo.getList("promo_tiers") ?? []
                        const promoTiers = rawPromoTiers.map(
                          (tier): IPromoTier => {
                            return {
                              description: tier.getString("description"),
                              duration: tier.getNumber("duration").toString(),
                              duration_type: tier.getString("duration_type"),
                              packageId: tier.getString("uuid"),
                              price: tier.getString("price"),
                              quantity: tier.getNumber("quantity").toString(),
                            }
                          }
                        )

                        promoTiers?.sort((a, b) => {
                          return Number(b.price) - Number(a.price)
                        })
                        const upcomingAvailableDates: { [key: string]: Date } =
                          {}
                        const availableDates =
                          promo.getListOfType<string>("available_dates")
                        availableDates.forEach((dateString: string) => {
                          upcomingAvailableDates[dateString] =
                            forceDateStringToDeviceTimeZone(dateString)
                        })
                        const nextAvailableDay =
                          promo.getString("next_available_day")
                        const soonestAvailableDate =
                          forceDateStringToDeviceTimeZone(nextAvailableDay)
                        const roleAvailability =
                          promo?.getListOfType<string>("availability") ?? []
                        return {
                          uuid: promo.getString("uuid"),
                          promoType: promo.getString("type"),
                          availability: roleAvailability,
                          slots: promo.getNumber("promos_by_platform"),
                          dateCarouselSelectedDate: soonestAvailableDate,
                          soonestAvailableDate: soonestAvailableDate,
                          upcomingAvailableDates: upcomingAvailableDates,
                          description: promo.getString("description"),
                          userProfile: false, // this property is never used
                          deletePromo: () => undefined, // this property is never used
                          packages: promoTiers,
                          platform: social.getString("platform"),
                          mediaRequirement: promo.getString(
                            "media_requirement"
                          ) as MediaRequirement,
                          mediaContentType: promo.getString(
                            "media_content_type"
                          ) as MediaContentType,
                          linkRequirement: promo.getString(
                            "link_requirement"
                          ) as LinkRequirement,
                          customRequirement1: promo.getString(
                            "custom_requirement_1"
                          ),
                          customRequirement2: promo.getString(
                            "custom_requirement_2"
                          ),
                          customRequirement3: promo.getString(
                            "custom_requirement_3"
                          ),
                        }
                      }
                    )
                    // const socialUrl = socialStats.find(
                    //   (s) => s.platform === key
                    // )?.url
                    parsedPromos.push({
                      brand: key,
                      defaultOpen: index === 0,
                      userName: social.getString("platform_username"),
                      // subscriberCount: 0,
                      isPersonalProfile: false,
                      deletePromo: () => undefined, // this property is never used
                      promos: availablePromos,
                      selectedDate: new Date(
                        social.getString("soonest_available")
                      ),
                      blossmProfileName: name,
                      // linkToSocials: socialUrl ? socialUrl : "",
                    })
                  })
                }
              )
              setPromos(parsedPromos)
            })
            .catch((error) => console.error(error))
          get("/profile_tags/").then((tagsResponse) => {
            setTagsCollection(
              tagsResponse.data.tags.map((t: never) => {
                return {
                  tag: t[1],
                  id: t[0],
                }
              })
            )
          })
        })
        .catch((error) => console.error(error))
    }
  }, [name])

  // error page
  if (!name) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <Paper
            component={Stack}
            direction="column"
            justifyContent="center"
            alignItems="center"
            width={isMobile ? 335 : 475}
            gap="32px"
            sx={{ padding: "16px 24px" }}
            borderRadius="16px"
          >
            <img height={34} alt={"blossm logo"} src={BlossmLogoSmall.src} />
            <Typography
              variant={isMobile ? "h6" : "h6"}
              fontWeight={"700"}
              textAlign={"center"}
            >
              Oops! We cant find that page.
            </Typography>
            <Typography variant={"body1"} textAlign={"center"}>
              Error 404
            </Typography>
            <Button
              onClick={() => {
                router.back()
              }}
              size={"medium"}
              variant={"text"}
            >
              Go Back
            </Button>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  return (
    <Layout>
      <>
        {nameplateProps !== undefined && socialStats !== undefined && (
          <>
            {myProfile?.hasCustomAvatar && (
              <SEO
                title={`Blossm - ${nameplateProps.profile.name}`}
                description={
                  myProfile?.aboutMe.join(" ").trim() + description_meta
                }
                ogImageUrl={nameplateProps.avatar.profileImage}
                ogImageType="image/jpeg"
                ogImageSize="300"
              />
            )}
            {!myProfile?.hasCustomAvatar && (
              <SEO
                title={`Blossm - ${nameplateProps.profile.name}`}
                description={
                  myProfile?.aboutMe.join(" ").trim() + description_meta
                }
              />
            )}

            <ProfileView
              tags={tagsCollection.filter(
                (t) => userSelectedTags.indexOf(t.id) !== -1
              )}
              blurb={myProfile?.aboutMe || [""]}
              profileImage={nameplateProps.avatar.profileImage || ""}
              profileUuid={myProfile?.uuid}
              accountName={nameplateProps.profile.name}
              accountHandle={nameplateProps.profile.handle}
              rating={nameplateProps.profile.rating}
              isPersonalProfile={isPersonalAccount}
              socialStats={{
                stats: socialStats,
                profiles: socialAccounts.filter(
                  (account) => account.verified === true
                ),
              }}
              promoDates={{
                currentDate: new Date(),
                dates: availableDates,
              }}
              availablePromos={promos}
              deletePromo={deletePromo}
              name={name}
              verified={nameplateProps.verified}
              isCustomerSupport={nameplateProps.profile.isCustomerSupport}
              isStaff={nameplateProps.profile.isStaff ?? false}
              accountSetupProgress={getAccountSetupProgress(
                myProfile?.userRole ?? "",
                context.phoneNumber,
                context.isPhoneValidated
              )}
              promoHeaderStats={promoHeaderStats}
              role={myProfile?.userRole as roleOptions}
              online={myProfile?.online || false}
            />
          </>
        )}
      </>
    </Layout>
  )
}

export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<{ props: IProfilePageProps }> {
  const config = createSsrRequestConfig(context)

  if (context?.params?.profileName) {
    const name = context?.params?.profileName as string
    let validateProfileResponse
    try {
      validateProfileResponse = await get(
        `${getBackendUrl()}/auth/profile/?name=${encodeURI(
          validateUrlParam(name.toLowerCase(), [/[^a-zA-Z0-9_]+$/g])
        )}`,
        {},
        config as AxiosRequestConfig
      )
    } catch (errors) {
      console.error(errors)
    }

    if (
      validateProfileResponse !== undefined &&
      validateProfileResponse.data.length > 0
    ) {
      const profileData = ArgJSONMap.fromParsedJson(
        validateProfileResponse.data[0]
      )
      return {
        props: {
          name: profileData.getString("name"),
          isPublicRoute: true,
        },
      }
    }
  }

  return {
    props: {
      name: "",
      isPublicRoute: true,
    },
  }
}

export default ProfilePage
