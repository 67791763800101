import { Box, Typography, useTheme } from "@mui/material"
import styled from "@emotion/styled"

export interface ITabPanel {
  children: JSX.Element | JSX.Element[]
  value: number
  index: number
  padding?: string
}
export const TabPanel = ({
  children,
  value,
  index,
  padding,
}: ITabPanel): JSX.Element => {
  const theme = useTheme()
  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      className={value !== index ? "hidden" : ""}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box
          sx={{
            p: !padding ? 3 : padding,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </StyledTabPanel>
  )
}

const StyledTabPanel = styled.div`
  flex-grow: 1;

  .hidden {
    display: none !important;
  }
`
