import styled from "@emotion/styled"
import { Dialog, Snackbar, useTheme, Typography, Stack } from "@mui/material"
import React, { useState, useContext, useEffect, useMemo } from "react"
import Link from "next/link"
import { getNextAvailableDateLabel } from "../../../utils/dates"
import { deleteRequest } from "../../../utils/requests"
import Alert from "@mui/material/Alert"
import { useRouter } from "next/router"
import { UserContext } from "../../../contexts/user"
import {
  ErrorTextButton,
  InverseButton,
  PrimaryButton,
  SecondaryButton,
} from "../Buttons"
import { PromoCard } from "../../Molecules/Promo/PromoPreview"
import { IPromoTier } from "../../Molecules/Promo/CreatePromo"
import { Box } from "@mui/system"
import {
  LinkRequirement,
  MediaContentType,
  MediaRequirement,
} from "../Promo/PromoRequirements"
import { getRoleSubtitle, roleOptions } from "../ProfileRoleBadge"

export interface IProfilePromoProps {
  uuid: string
  promoType: string
  availability: string[]
  slots?: number
  dateCarouselSelectedDate?: Date
  upcomingAvailableDates: { [key: string]: Date }
  soonestAvailableDate: Date
  description: string
  packages: IPromoTier[]
  userProfile: boolean
  platform: string
  isLastItem?: boolean
  deletePromo(uuid: string): void
  mediaRequirement: MediaRequirement
  mediaContentType: MediaContentType
  linkRequirement: LinkRequirement
  customRequirement1: string
  customRequirement2: string
  customRequirement3: string
}

export const ProfilePromo = ({
  uuid,
  promoType,
  availability,
  dateCarouselSelectedDate,
  upcomingAvailableDates,
  soonestAvailableDate,
  description,
  packages,
  userProfile,
  platform,
  deletePromo,
  isLastItem = false,
  mediaRequirement,
  mediaContentType,
  linkRequirement,
  customRequirement1,
  customRequirement2,
  customRequirement3,
}: IProfilePromoProps): React.JSX.Element => {
  const theme = useTheme()
  const router = useRouter()
  const [pckage, setPackage] = useState(
    packages.length > 0 ? packages[0] : null
  )
  const { context } = useContext(UserContext)
  const [availableDateLabel, setAvailableDateLabel] = useState<string>("")
  const [selectedPromoTier, setSelectedPromoTier] = useState<
    IPromoTier | undefined
  >()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [errorAlertOpen, setErrorAlertOpen] = useState(false)
  const [promoSelectedDate, setPromoSelectedDate] = useState<Date>(new Date())

  useEffect(() => {
    if (packages.length > 0) {
      setSelectedPromoTier(packages[0])
    }
  }, [packages])

  const buyLinkUrl = useMemo(() => {
    if (
      context.authenticationAttempted &&
      context.isAuthenticated &&
      promoSelectedDate !== null &&
      promoSelectedDate !== undefined &&
      !isNaN(promoSelectedDate.valueOf())
    ) {
      const day =
        promoSelectedDate.getDate() < 10
          ? `0${promoSelectedDate.getDate()}`
          : `${promoSelectedDate.getDate()}`
      const month =
        promoSelectedDate.getMonth() + 1 < 10
          ? `0${promoSelectedDate.getMonth() + 1}`
          : `${promoSelectedDate.getMonth() + 1}`
      const date = `${promoSelectedDate.getFullYear()}-${month}-${day}T00:00:00.000Z`
      return `/promo/${uuid}/?requestedDate=${date}${
        selectedPromoTier !== undefined
          ? `&tier=${selectedPromoTier?.packageId}`
          : ""
      }${platform !== undefined ? `&platform=${platform}` : ""}${
        context.profileName !== "" ? `&profileName=${context.profileName}` : ""
      }${context.uuid !== "" ? `&profileUuid=${context.uuid}` : ""}`
    } else {
      return "/login"
    }
  }, [uuid, promoSelectedDate, selectedPromoTier?.packageId])

  useEffect(() => {
    if (
      dateCarouselSelectedDate !== undefined &&
      upcomingAvailableDates !== undefined
    ) {
      let dateFormatted
      if (dateCarouselSelectedDate.getTimezoneOffset() < 0) {
        const tzoffset = new Date().getTimezoneOffset() * 60000
        const date = new Date(dateCarouselSelectedDate.getTime() - tzoffset)
        dateFormatted = date.toISOString().split("T")[0] + "T00:00:00+0000"
      } else {
        dateFormatted =
          dateCarouselSelectedDate.toISOString().split("T")[0] +
          "T00:00:00+0000"
      }
      if (upcomingAvailableDates[dateFormatted] !== undefined) {
        setPromoSelectedDate(upcomingAvailableDates[dateFormatted])
        setAvailableDateLabel(
          getNextAvailableDateLabel(
            upcomingAvailableDates[dateFormatted].toUTCString()
          )
        )
      } else {
        setPromoSelectedDate(soonestAvailableDate)
        setAvailableDateLabel(
          getNextAvailableDateLabel(soonestAvailableDate.toUTCString())
        )
      }
    }
  }, [dateCarouselSelectedDate])

  // useEffect(() => {
  //     if (context.promoRequestsRemaining !== undefined && context.promoRequestsRemaining === 0) {
  //         setNoPromoRequestRemainingModalData({header: "Promo Purchase Limit Reached", body: "You're at the maximum limit of Pending and Accepted promo requests. Wait for sellers to accept your pending promo requests or wait for them to complete your outstanding Accepted promo requests to buy more promo."})
  //      else {
  //         setNoPromoRequestRemainingModalOpen(false)
  //     }
  // }, [context.promoRequestsRemaining])

  function handleNotBuy(): void {
    if (!context.userIdVerified) {
      router.push("/id-verification/verify?source=buynow")
    }
  }
  const EditPromoButton = (): React.JSX.Element => {
    return (
      <Box
        sx={{
          minWidth: "100%",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            justifyContent: "space-between",
            paddingTop: "16px",
          }}
        >
          <InverseButton
            sx={{
              borderRadius: "24px",
            }}
            variant={"outlined"}
            onClick={() => setIsEditModalOpen(true)}
          >
            Edit Promo
          </InverseButton>
          <ErrorTextButton onClick={() => setIsDeleteModalOpen(true)}>
            Delete
          </ErrorTextButton>
        </Stack>
        <Dialog
          open={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false)
          }}
          PaperProps={{
            sx: {
              borderRadius: "1rem",
            },
          }}
        >
          <StyledDeleteModalContent>
            <Typography variant={"h5"}>Edit this Promo?</Typography>
            <Typography variant={"body2"} color={theme.palette.text.secondary}>
              <div>
                Saving any edits to this promo will cancel all pending requests.
              </div>
            </Typography>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <SecondaryButton
                variant={"text"}
                onClick={() => {
                  router.push({
                    pathname: "/edit-promo/",
                    query: {
                      promo: uuid,
                    },
                  })
                }}
              >
                Edit
              </SecondaryButton>
              <ErrorTextButton onClick={() => setIsEditModalOpen(false)}>
                Cancel
              </ErrorTextButton>
            </Stack>
          </StyledDeleteModalContent>
        </Dialog>
        <Dialog
          open={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false)
          }}
          PaperProps={{
            sx: {
              borderRadius: "1rem",
            },
          }}
        >
          <StyledDeleteModalContent>
            <Typography variant={"h5"}>Delete this Promo?</Typography>
            <Typography variant={"body2"} color={theme.palette.text.secondary}>
              <div>
                Deleting this promo will cancel any pending requests for this
                promo.
              </div>
              <div>It will NOT cancel any accepted requests.</div>
              <div>This action cannot be undone.</div>
            </Typography>
            <div className={"delete-control-row"}>
              <ErrorTextButton
                onClick={() => {
                  deleteRequest(`/promo_edit/${uuid}/`)
                    .then(() => {
                      deletePromo(uuid)
                    })
                    .catch(() => {
                      setErrorAlertOpen(true)
                    })
                }}
              >
                Delete
              </ErrorTextButton>
              <InverseButton onClick={() => setIsDeleteModalOpen(false)}>
                Cancel
              </InverseButton>
            </div>
          </StyledDeleteModalContent>
        </Dialog>
        <Snackbar
          open={errorAlertOpen}
          autoHideDuration={6000}
          onClose={() => setErrorAlertOpen(false)}
        >
          <Alert variant="filled" severity={"error"}>
            Error deleting promo
          </Alert>
        </Snackbar>
      </Box>
    )
  }
  const BookPromoButton = (): React.JSX.Element => {
    const buyButtonText = "Book for " + availableDateLabel

    const sharedButtonStyling = {
      width: "100%",
      borderRadius: "1rem",
      pl: "16px",
      pr: "16px",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    }

    return (
      <Box sx={{ width: "100%" }}>
        {context.userIdVerified &&
        (context.promoRequestsRemaining >= 1 || context.isEndorsed) ? (
          <Link href={buyLinkUrl}>
            <PrimaryButton sx={sharedButtonStyling}>
              {buyButtonText}
            </PrimaryButton>
          </Link>
        ) : (
          <Link
            href={
              context.authenticationAttempted && context.isAuthenticated
                ? "javascript:void(0)"
                : "/login"
            }
          >
            <PrimaryButton
              sx={sharedButtonStyling}
              onClick={() => handleNotBuy()}
              fullWidth
            >
              {buyButtonText}
            </PrimaryButton>
          </Link>
        )}
      </Box>
    )
  }
  const promoAvailabilityText =
    availability.length === 1
      ? getRoleSubtitle(availability[0] as roleOptions)
      : "Available to Everyone"

  if (!pckage) {
    return <></>
  }
  return (
    <PromoCard
      isListItem={true}
      lastInList={isLastItem}
      selectedPackage={pckage}
      setSelectedPackage={(currentPkg) => {
        setPackage(currentPkg)
        setSelectedPromoTier(currentPkg)
      }}
      numSlots={0}
      platform={platform}
      promoTitle={promoType}
      promoDescription={description}
      packages={packages}
      promoAvailabilityText={promoAvailabilityText}
      PromoPackageButton={
        userProfile ? <EditPromoButton /> : <BookPromoButton />
      }
      mediaRequirement={mediaRequirement}
      mediaContentType={mediaContentType}
      linkRequirement={linkRequirement}
      customRequirement1={customRequirement1}
      customRequirement2={customRequirement2}
      customRequirement3={customRequirement3}
    />
  )
}

const StyledDeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 16px;
  height: fit-content;
  width: fit-content;
  gap: 32px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
`
