import styled from "@emotion/styled"
import { alpha, Theme, Typography, useTheme } from "@mui/material"
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { useEffect, useState } from "react"
import useWindowDimensions, {
  getWindowDimensions,
} from "../../../utils/windowDimensions"
import { Colors } from "../../../styles/Colors"

export interface IPromoDateSelectorProps {
  dates: Date[]
  setDate?: (date: Date) => void
  setPosition?: (position: number) => void
  currentDate: Date
  currentPosition?: number
}

export const PromoDateSelector = ({
  dates,
  setDate,
  currentDate,
  setPosition,
  currentPosition = 0,
}: IPromoDateSelectorProps): JSX.Element => {
  const theme = useTheme()
  const [width, setWidth] = useState(0)
  const [startPosition, setStartPosition] = useState(currentPosition)
  const [selectedItem, setSelectedItem] = useState(
    dates.find(
      (date) =>
        date.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }) ===
        currentDate.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
    )
  )
  useEffect(() => {
    const { width } = getWindowDimensions()
    if (width > 720) {
      setWidth(5)
    } else if (width > 600) {
      setWidth(5)
    } else {
      setWidth(5)
    }
  }, [useWindowDimensions()])

  function handleItemSelect(item: Date): void {
    setSelectedItem(item)
    if (setDate) {
      setDate(item)
    }
  }

  function getCarouselItems(): Date[] {
    const carouselItems: Date[] = []
    const start = startPosition
    const end = start + width > dates.length ? dates.length : start + width
    for (let i = startPosition; i < end; i++) {
      carouselItems.push(dates[i])
    }

    return carouselItems
  }

  function moveCarousel(direction: "Forward" | "Back"): void {
    let start = startPosition

    switch (direction) {
      case "Forward":
        start = start + width > dates.length ? start : start + width
        break
      case "Back":
        start = start - width <= 0 ? 0 : start - width
    }
    handleStartPositionUpdate(start)
  }

  function handleStartPositionUpdate(position: number): void {
    if (setPosition) {
      setPosition(position)
    }
    setStartPosition(position)
  }

  return (
    <StyledPromoDateSelector>
      <div className={"promo-date-carousel"}>
        <div className={"promo-date-carousel-control"}>
          {startPosition !== 0 && (
            <ChevronLeft
              cursor={"pointer"}
              onClick={() => moveCarousel("Back")}
            />
          )}
        </div>
        {getCarouselItems().map((date, index) => {
          return (
            <StyledDateItem
              theme={theme}
              selected={
                date.toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                }) ===
                selectedItem?.toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })
              }
              key={index}
              onClick={() => handleItemSelect(date)}
            >
              <Typography
                color={
                  date.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }) ===
                  selectedItem?.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })
                    ? theme.palette.secondary.main
                    : theme.palette.text.secondary
                }
                variant={"body2"}
                fontWeight={"bolder"}
              >
                {
                  ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
                    date.getDay()
                  ]
                }
              </Typography>
              <Typography
                noWrap
                color={
                  date.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }) ===
                  selectedItem?.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })
                    ? theme.palette.secondary.main
                    : theme.palette.text.secondary
                }
                variant={"body2"}
              >
                {date.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })}
              </Typography>
            </StyledDateItem>
          )
        })}
        {startPosition + width < dates.length - 1 && (
          <ChevronRight
            cursor={"pointer"}
            onClick={() => moveCarousel("Forward")}
          />
        )}
      </div>
    </StyledPromoDateSelector>
  )
}

const StyledPromoDateSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .promo-date-carousel {
    display: flex;
    gap: 4px;
    align-items: center;

    .promo-date-carousel-control {
      width: 23px;
    }
  }
`

const StyledDateItem = styled.div<{ selected: boolean; theme: Theme }>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  user-select: none;
  border-radius: 8px;
  background: var(--Contrast-White, #fff);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  min-width: 60px;
  width: 60px;
  background-color: ${(props) =>
    props.selected ? alpha(props.theme.palette.primary.main, 1) : Colors.white};
`
