import styled from "@emotion/styled"
import {
  Brands,
  getBrandDisplayName,
  getBrandGradientAndIcon,
} from "../../Atoms/Profile/Header/Logo"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  IProfilePromoProps,
  ProfilePromo,
} from "../../Atoms/Profile/ProfilePromo"
import { HelpOutlined, Launch } from "@mui/icons-material"
import React, { useContext, useMemo, useState } from "react"
import { InverseButton } from "../../Atoms/Buttons"
import { Colors } from "../../../styles/Colors"
import { UserContext } from "../../../contexts/user"
import { roleOptions } from "../../Atoms/ProfileRoleBadge"

export interface IPromoHeaderStat {
  brand: Brands
  social_account_username: string
  header: string
  subtitle: string
  lastUpdated: Date
  isExpired: boolean
  expireDays: number
}

export interface IAvailablePromosProps {
  brand: string
  defaultOpen?: boolean
  userName: string
  blossmProfileName: string
  // subscriberCount: number,
  promos: IProfilePromoProps[]
  isPersonalProfile: boolean
  selectedDate: Date
  socialStats?: IPromoHeaderStat[]
  linkToSocials?: string

  deletePromo(uuid: string): void
}

export const AvailablePromos = ({
  brand,
  userName,
  promos,
  deletePromo,
  isPersonalProfile,
  selectedDate,
  socialStats,
  blossmProfileName,
  linkToSocials,
}: IAvailablePromosProps): React.JSX.Element => {
  const relevantStats = socialStats?.filter(
    (stat) =>
      stat.brand === (brand as Brands) &&
      stat.social_account_username === userName
  )
  const { context } = useContext(UserContext)
  const availablePromos = useMemo(
    () =>
      isPersonalProfile || !context.userRole
        ? promos
        : promos.filter((promo) => {
            return promo.availability.includes(
              context.userRole === roleOptions.agency
                ? roleOptions.independentCreator
                : context.userRole
            )
          }),
    [promos]
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [dialogTitle, setDialogTitle] = useState<string>("")
  const [dialogBody, setDialogBody] = useState<string>("")

  return (
    <StyledAvailablePromos>
      <StyledAvailablePromosHeader
        isMobile={isMobile}
        backgroundColor={getBrandGradientAndIcon(brand).background}
        numberOfStats={relevantStats ? relevantStats?.length : 0}
      >
        <div className={"available-promos-header-left"}>
          <Stack
            direction={isSmallMobile ? "column" : "row"}
            gap={"8px"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            <img
              className={"available-promos-header-icon"}
              src={getBrandGradientAndIcon(brand).icon}
            />
            <div className={"available-promos-header-text-stack"}>
              <Typography
                variant={"subtitle2"}
                color={getBrandGradientAndIcon(brand).textColor}
              >
                {getBrandDisplayName(brand as Brands) === "OnlyFans"
                  ? isMobile
                    ? "OF"
                    : "OnlyFans"
                  : getBrandDisplayName(brand as Brands)}
              </Typography>
              <Stack
                direction={"row"}
                gap={"4px"}
                onClick={() => window.open(linkToSocials, "_blank")}
              >
                <Typography
                  variant={"subtitle2"}
                  color={getBrandGradientAndIcon(brand).textColor}
                  textOverflow={"ellipsis"}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  @{userName}
                </Typography>
                <Launch htmlColor={Colors.action.active.dark} />
              </Stack>
            </div>
          </Stack>
        </div>
        {relevantStats && relevantStats.length > 0 && (
          <SocialStatsDisplay>
            {relevantStats.map((stat, index) => {
              return (
                <Stat key={stat.brand + index}>
                  <Typography
                    fontWeight={"bold"}
                    variant={"body2"}
                    color={getBrandGradientAndIcon(brand).textColor}
                  >
                    {stat.header}
                  </Typography>
                  <Typography
                    variant={"caption"}
                    color={getBrandGradientAndIcon(brand).textColor}
                  >
                    {stat.subtitle}
                  </Typography>
                </Stat>
              )
            })}
            <HelpOutlined
              sx={{
                color:
                  brand.toLowerCase() === "snapchat"
                    ? Colors.black
                    : Colors.white,
              }}
              onClick={() => {
                const isExpired =
                  relevantStats.filter((stat) => stat.isExpired).length > 0
                const updatedDate =
                  relevantStats.length > 0
                    ? relevantStats[0].lastUpdated.toLocaleDateString(
                        undefined,
                        {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }
                      )
                    : ""
                const expireDays =
                  relevantStats.length > 0 ? relevantStats[0].expireDays : 0
                setDialogTitle(
                  isExpired ? "Verification Outdated" : "Verified by Blossm"
                )
                setDialogBody(
                  isExpired
                    ? `${blossmProfileName}'s ${brand} stats have not been verified in the last ${expireDays} days.`
                    : `${userName}'s ${brand} stats were verified by Blossm staff as of ${updatedDate}.`
                )
                setDialogOpen(true)
              }}
            />
          </SocialStatsDisplay>
        )}
      </StyledAvailablePromosHeader>
      {availablePromos.map((promo, index) => {
        const lastIndex = availablePromos.length - 1
        return (
          <ProfilePromo
            isLastItem={index === lastIndex}
            {...promo}
            deletePromo={deletePromo}
            dateCarouselSelectedDate={selectedDate}
            key={promo.uuid}
            userProfile={isPersonalProfile}
            platform={brand}
          />
        )
      })}
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
          setDialogTitle("")
          setDialogBody("")
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{dialogBody}</DialogContent>
        <DialogActions>
          <InverseButton
            onClick={() => {
              setDialogOpen(false)
              setDialogTitle("")
              setDialogBody("")
            }}
          >
            Ok
          </InverseButton>
        </DialogActions>
      </Dialog>
    </StyledAvailablePromos>
  )
}

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
`

const SocialStatsDisplay = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  gap: 10px;
`

const StyledAvailablePromosHeader = styled.div<{
  backgroundColor: string
  isMobile: boolean
  numberOfStats: number
}>`
  background: ${(props) => props.backgroundColor};
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;
  justify-content: space-between;
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.22);

  .available-promos-header-text-stack {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .available-promos-header-text-stack.right {
    align-items: center;
  }

  .available-promos-header-left {
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: ${(props) =>
      props.isMobile && props.numberOfStats > 1 ? "hidden" : "show"};

    .available-promos-header-icon {
      width: 32px;
      height: 32px;
    }
  }

  .available-promos-header-right {
    display: flex;
    gap: 32px;
    align-items: center;
  }
`

const StyledAvailablePromos = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  height: fit-content;
`
