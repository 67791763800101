import { Box, Typography } from "@mui/material"
import { UserType } from "../../contexts/user"

export interface ISStaffLinksProps {
  profileUsername: string
  profileIsStaff: boolean
  isPersonalProfile: boolean
  userContext: UserType
}

export const StaffLinks = (props: ISStaffLinksProps): JSX.Element => {
  return (
    <>
      {props.userContext.isStaff && (
        <Box
          sx={{
            backgroundColor: "#eeeeee",
            marginBottom: "16px",
            padding: "16px",
            borderRadius: "8px",
            display: "flex",
            gap: "8px",
            "a": { color: "#3f51b5", padding: "8px" },
          }}
        >
          <Typography variant="caption" sx={{ paddingRight: "8px" }}>
            Staff Links:
          </Typography>
          <Typography variant="caption">
            <a
              href={`/staff_tools/dashboard/user_info/?username=${props.profileUsername}`}
            >
              Dashboard
            </a>
            {props.profileUsername !== props.userContext.username &&
              !props.isPersonalProfile &&
              !props.profileIsStaff && (
                <>
                  |
                  <a
                    href={`/staff_tools/impersonate/${props.profileUsername}/`}
                    onClick={() => {
                      window.sessionStorage.removeItem("profileUuid")
                    }}
                  >
                    Impersonate
                  </a>
                </>
              )}
          </Typography>
        </Box>
      )}
    </>
  )
}
